<template>
  <v-autocomplete  :solo="solo" outlined :reverse="reverse" :dense="dense" :name="name"
    @keydown.enter="fetchData()" @keydown="keydown" :class="classes" v-model="input" :items="items ? items : []"
    :item-text="selectedLabel" :item-value="selected_prop" :append-icon="icon ? icon : 'mdi-menu-down'"
    :item-disabled="checkIsItemDisabled" :color="color" :background-color="background"
    :rules="required == true ? $global.requiredRule : $global.notRequiredRule" :multiple="multi ? true : false"
    :no-data-text="$i18n.t('no selects')" :loading="isRequestLoading || isLoading" :disabled="disabled" :chips="multi"
    :small-chips="multi" :hide-details="hideDetails == true ? 'auto' : false" :clearable="clearable || multi"
    :deletable-chips="multi" :error-messages="$i18n.t(errorMessage)" :persistent-hint="hint ? true : false"
    :hint="$i18n.t(hint)" :placeholder="$i18n.t(placeholder)" :search-input.sync="search" @click:clear="items = []" :no-filter="items.length > 0">
    <template v-slot:prepend-item v-if="Array.isArray(value)" >

      <v-list-item v-if="multi" ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="value.length && value.length == lookups.length ? 'error' : color">
            {{ checkIcon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('select all') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="multi" class="mt-2"></v-divider>
    </template>
    <template v-slot:label>
      <label v-if="label">
        {{ $t(label) }}
        <span class="error--text" v-if="required == true && label">*</span>
      </label>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer"></slot>
    </template>
    <template v-slot:append>
      <slot name="append"></slot>
    </template>
    <template v-slot:prepend-inner>
      <slot name="prepend-inner"></slot>
    </template>
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>
    <template  v-slot:selection="{ item }">
      {{ item[selectedLabel] }}
    </template>
    <template v-slot:item="{ item }">
      {{ item[selectedLabel] }}
    </template>
  </v-autocomplete>
</template>


<script>
export default {
  name: "AsyncAutoCompleteInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    lookups: { default: null },
    searchEndPoint: { default: null },
    responseKey: { default: 'data' },
    required: { default: false },
    dense: { default: true },
    lookups_value: { default: null },
    multi: { default: false },
    selected_label: { default: null },
    selected_prop: { default: null },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "secondary" },
    background: { default: null },
    icon: { default: "" },
    hideDetails: { default: false },
    inputDesign: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    disableItem: { default: null },
    placeholder: { default: null },
    errorMessage: { default: null },
    hint: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false },
    has_slot: { default: false },
    tabKey: { default: Function },
  },
  data: () => ({
    search: null,
    isRequestLoading: false,
    items: [],
  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    selectedLabel() {
      return this.selected_label.replaceAll(',', '_')
    },
    selectAll() {
      return this.lookups.length === this.value.length
    },
    selectSome() {
      return this.lookups.length > 0
    },
    checkIcon() {
      if (this.selectAll) return 'mdi-close-box'
      if (this.selectSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    search(val) {
      let getIndex = 0;
      let selected = false;
      if (this.input) {
        getIndex = this.items.findIndex(item => item[this.selected_prop] === this.input)
        selected = this.items[getIndex][this.selectedLabel] == val
        console.log('selected', selected);
      }

      if (this.search && !selected ) {
        setTimeout(() => {
          if (this.search === val) {
            this.fetchData(val)
          }
        }, 750);
      } else {
        this.items = [];
      }
    }
  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    fetchData() {
      this.isRequestLoading = true;
      this.$api.GET_METHOD(`${this.searchEndPoint}${this.search || ''}`).then(response => {
        this.isRequestLoading = false;
        if (response) {
          console.log(response);
          if (response.check) {
            let labels = this.selected_label?.split(',');
            response[this.responseKey].forEach(item => {
              item[this.selectedLabel] = ''
              labels.forEach((label, index) => {
                if (item[label]) {
                  item[this.selectedLabel] = index ? `${item[this.selectedLabel]} - ${item[label] || ''}` : item[label]
                }
              });
            });
            this.items = response.data || [];
            this.$emit("filteredLookups", this.items);
          }
        }
      })
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.$emit("input", []);
        } else {
          let ids = []
          this.lookups.slice().forEach(item => {
            ids.push(item[this.selected_prop])
          });
          this.$emit("input", ids);
        }
      })
    },
    checkIsItemDisabled(item) {
      if (this.disableItem) {
        if (item[this.disableItem.key] == this.disableItem.value && typeof this.disableItem !== "string") {
          return true;
        }
        if (typeof this.disableItem === "string") {
          return item.disable
        }
        return false;
      }
      else {
        return false;
      }
    },
    keydown(e) {
      console.log(e);
      if (e.code == "Tab") {
        this.tabKey(true)
      }
    },
  },
};
</script>



<style scoped lang="scss"></style>
