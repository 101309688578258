import store from "../store";
// import html2canvas from 'html2canvas';
const ALLOW_ERRORS = true;
const ALLOW_SCREEN_SHOT = false;
export default {
  install(Vue) {

    Vue.prototype.$issues = {
      SEND_ISSUE(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD, MESSAGE, DESCRIPTION) {
        if (ALLOW_ERRORS) {
          if (ALLOW_SCREEN_SHOT) {
            setTimeout(() => {
              // this.TAKE_SCREEN_SHOT(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD, MESSAGE , DESCRIPTION)
            }, 1000);
          } else {
            this.SEND_REQUEST(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD, MESSAGE, DESCRIPTION)
          }
        }
      },
      // async TAKE_SCREEN_SHOT(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD, MESSAGE , DESCRIPTION) {
      //   const elementToCapture = document.getElementById('app');
      //   html2canvas(elementToCapture)
      //     .then(canvas => {
      //       const screenshot = canvas.toDataURL('image/png');
      //       console.log('screenshot', screenshot);
      //       this.SEND_REQUEST(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD, MESSAGE , DESCRIPTION, screenshot)
      //     });
      // },
      async SEND_REQUEST(STATUS, ERROR, ENDPOINT, PAYLOAD, METHOD, MESSAGE, DESCRIPTION, SCREEN) {
        const PLATFORM_ENDPOINT = process?.env?.VUE_APP_PROJECT == 'qarat_erp_live' && store.state.clientID !== 'test' ? 'https://platform-api.cloudsecretsksa.com' : 'https://platform-api.cs-testing.com';
        const ACCESS_TOKEN = "xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EB"
        try {
          let request_body = {
            project_id: 1,
            platform: `qarat_pos@${store.state.app_version}`,
            client_id: store.state.clientID || null,
            system_user_id: store.state.accessToken || null,
            screen_code: '00-000',
            screen_name: 'Screen in POS',
            branch_id: store.state.current_branch ? store.state.current_branch : null,
            year_id: store.state.current_year ? store.state.current_year : null,
            endpoint: ENDPOINT || null,
            method: METHOD || null,
            payload: PAYLOAD ? JSON.stringify(PAYLOAD) : null,
            response: ERROR ? JSON.stringify(ERROR) : null,
            error_status: STATUS,
            error_message: ERROR ? ERROR.message : null,
            screenshot: SCREEN,
            comment: null,
            frontend_description: DESCRIPTION,
          };
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", "access-token": ACCESS_TOKEN },
            body: JSON.stringify(request_body)
          };
          const response = await fetch(`${PLATFORM_ENDPOINT}/api/v1/en/error_store`, requestOptions);
          if (MESSAGE && await response.json()) {
            store.state.snackbarTitle = MESSAGE.title;
            store.state.snackbarType = MESSAGE.type;
            store.state.showSnackbar = true;
          }
          return request_body;
        } catch (error) {
          // console.log(error);
        }
      },
    };
  },
};
