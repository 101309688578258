<template>
  <router-view></router-view>
</template>



<script>

export default {
  name: "SalesDeposits",
  mounted() {
    document.title = `${this.$i18n.t("Qarat - ")}${this.$global.FilterPermissions("06-028").sub_title}`;
  }
};
</script>
