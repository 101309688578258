<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <div class="subtitle-2 text--disabled">
            <strong>{{ $global.FilterPermissions("06-028").sub_title }} . {{ $t("list") }}</strong>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="controlRoute"
            createBtnLabel="create customer deposit" :data="rows" :header="header">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'return_deposit'">
                <v-btn v-if="!row.is_closed" @click="refundConfirmation(row, index)" color="primary" class="mx-1 my-1"
                  :loading="printLoading.loading && printLoading.id == row.m_id" small>
                  {{ $t('refund deposited') }}
                </v-btn>
                <span v-else class="font-weight-bold caption" :class="row.is_closed ? 'blue3--text' : ' error--text'">
                  {{ row.return_deposit_status }}</span>
              </div>
              <div v-else>
                <v-btn v-if="$global.CheckAction('06-028', 5)" @click="printDeposit(row)"
                  :loading="printLoading.loading" class="pa-0" width="20" height="20" x-small icon fab>
                  <img src="@/assets/img/svg/pdf.svg" alt="Qarat-POS" />
                </v-btn>
                <v-btn @click="edit(row)" class="blue12 ma-1 mx-2" x-small icon fab>
                  <v-icon color="blue1">mdi-eye-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>
      </v-row>
    </v-container>
    <CustomModal :width="500" :persistent="true" :dialog="refundDepositDialog" :buttonOneLoading="printLoading.loading"
      :buttonTwoMethod="() => refundDepositDialog = false" :buttonOneMethod="refundDeposit"
      @close="() => { refundDepositDialog = false; printLoading.id = null }" :dialogContent="{
        title: $i18n.t('are you want to return deposit ?'),
        img: null,
        details: $i18n.t('it will create payment voucher for customer'),
        buttonOneTitle: 'yes',
        buttonOneColor: 'blue12',
        buttonOneClass: 'blue1--text',
        buttonTwoTitle: 'no',
        buttonTwoColor: 'white',
        buttonTwoClass: 'red12',
        buttonTwoLoading: false,
        buttonTwoClass: 'error--text',
      }" />
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import Pagination from "@/components/Pagination";
import CustomModal from "@/components/modals/CustomModal.vue";
export default {
  name: "DepositsTable",

  data: () => ({
    isLoading: true,
    controlRoute: '/sales_deposit/create-deposit',
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 25,
    searchText: '',
    from: null,
    to: null,
    date: null,
    refundDepositDialog: false,
    printLoading: { loading: false, id: 0 },
  }),
  components: {
    DynamicTable,
    Pagination, CustomModal
  },
  computed: {},
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "#", key: "id", type: 'text' },
        { text: "customer", key: "customer_name", type: 'text' },
        { text: "description", key: "description", type: 'text' },
        { text: "amount", key: "amount", type: 'float' },
        { text: "treasury", key: "treasury_name", type: 'text' },
        { text: "machine", key: "machine_name", type: 'text' },
        { text: "bank", key: "bank_name", type: 'text' },
        { text: "journal", key: "journal_code", type: 'text' },
        { text: "action date", key: "action_date", type: 'text' },
        { text: "deposit status", key: "return_deposit", hideInPrint: true, type: 'slot', width: 80 },
        { text: "actions", key: "id", type: 'slot' },
      ]
    },
    getData() {
      this.isLoading = true;
      if (this.$global.CheckAction("06-028", 1)) {
        this.$api.GET_METHOD(`sales_deposit?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText || ''}&date=${this.date || ''}`).then((response) => {
          this.isLoading = false;
          response.data.items.data.forEach(row => {
            row.return_deposit_status = row.is_closed == 3 ? this.$t('is refund') : row.is_closed == 2 ? this.$t('used in sales') : this.$t('pending');
            row.disableEdit = row.disableDelete = row.is_closed === 3;
          });
          this.rows = response.data.items.data;
          this.page = response.data.items.current_page;
          this.pagination = {
            page: response.data.items.current_page,
            totalPages: response.data.items.last_page,
            per_page: response.data.items.per_page,
            totalRows: response.data.items.total,
          };
        })
      } else {
        this.$router.push('/')
      }
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getData()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
    edit(row) {
      this.$router.push(`/sales_deposit/deposit/${row.id}`)
    },
    refundConfirmation(row, index) {
      this.refundDepositDialog = true;
      this.printLoading.index = index;
      this.printLoading.id = row.id;
    },
    refundDeposit() {
      this.printLoading.loading = true;
      this.$api.POST_METHOD(`sales_deposit_refund/${this.printLoading.id}`, { is_closed: 1 }).then((response) => {
        this.printLoading.loading = false;
        if (response.check) {
          this.refundDepositDialog = false;
          this.getData()
        }
      })
    },
  },
};
</script>
